/* For use in src/lib/core/theming/_palette.scss */
$identifai-primary: (
  50: #ffffff,
  100: #e6e6e6,
  200: #9096a5,
  300: #646b80,
  400: #424c65,
  500: #212c4a,
  600: #1d2743,
  700: #18213a,
  800: #141b32,
  900: #111727,
  A100: #405691,
  A200: #405691,
  A400: #31416d,
  A700: #31416d,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$identifai-accent: (
  50: #e1eaf8,
  100: #b3caee,
  200: #81a7e3,
  300: #4f83d7,
  400: #2969cf,
  500: #034ec6,
  600: #0347c0,
  700: #023db9,
  800: #0235b1,
  900: #0125a4,
  A100: #ced6ff,
  A200: #9baaff,
  A400: #687fff,
  A700: #4f69ff,
  contrast: (
    50: #fff,
    100: #fff,
    200: #fff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #fff,
    A200: #fff,
    A400: #fff,
    A700: #ffffff,
  ),
);

$identifai-warn: (
  50: #f9e7e0,
  100: #f0c3b3,
  200: #e69b80,
  300: #db724d,
  400: #d45426,
  500: #cc3600,
  600: #c73000,
  700: #c02900,
  800: #b92200,
  900: #ad1600,
  A100: #ffdad7,
  A200: #ffaaa4,
  A400: #ff7b71,
  A700: #ff6358,
  contrast: (
    50: #fff,
    100: #fff,
    200: #fff,
    300: #fff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #fff,
    A200: #fff,
    A400: #fff,
    A700: #fff,
  ),
);
