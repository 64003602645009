table {
  width: 100%;
  max-width: 100%;
}
table > tr,
thead,
tbody {
  width: 100%;
  max-width: 100%;
}
.table-container {
  overflow-x: scroll;
}

th.mat-header-cell {
  text-align: center !important;
}
.mat-sort-header-container {
  justify-content: center;
}
