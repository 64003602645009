.badge {
  background: grey;
  padding: 5px 10px;
  color: white;
  border-radius: 15px;
  margin: 0 5px;
  text-align: center;
  white-space: nowrap;

  &--error {
    background: red;
  }

  &--success {
    background: green;
  }

  &--warning {
    background: rgb(255, 204, 0);
  }

  &--primary {
    background: #212c4a;
  }

  &--small  {
    font-size: smaller;
    padding: 2px 8px;
  }
}
