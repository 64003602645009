.cdk-overlay-container .cdk-overlay-pane {
  min-width: 600px;
}
.cdk-overlay-container
  .cdk-overlay-connected-position-bounding-box
  .cdk-overlay-pane {
  min-width: 0;
  width: auto;
}

.cdk-overlay-pane:has(snack-bar-container) {
  min-width: auto !important;
}

@media screen and (max-width: 600px) {
  .cdk-overlay-container .cdk-overlay-pane {
    width: 100%;
    min-width: 0;
  }
}
