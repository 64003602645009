@use '@angular/material' as mat;
@import '../theming/themes/theme';

/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
.la-icon,
.la-icon>div {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.la-icon {
  display: block;
  font-size: 0;
  color: #ffffff;
}

.la-icon.la-dark {
  color: #333;
}

.la-icon>div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}

.la-icon {
  width: 64px;
  height: 64px;

  animation: fade 0.7s alternate infinite;
}

@keyframes fade {
  from {
    opacity: 0.2;
  }

  to {
    opacity: 1;
  }
}