.graph-container {
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
.graph {
  // width: 100%;
  // min-height: 300px;
  padding: 1rem;
  &__title {
    text-align: center;
    cursor: pointer;
  }
}
.graph-1,
.graph-2 {
  width: 50%;
  min-height: 300px;
}

@media screen and (max-width: 600px) {
  .graph-container {
    flex-direction: column;
  }
  .graph-1,
  .graph-2 {
    width: 100%;
    min-height: 250px;
  }
}
